export class RegionSelector {
    constructor() {
        this.regionSelector = document.querySelector('#regionSelect');
        this.citySelector = document.querySelector('#citySelect');
        
        if (!this.regionSelector || !this.citySelector)
            return;
        
        this.selectedCity = null;
        this.selectedRegion = null;

        this.regionSelector.addEventListener('change', (e) => {
            this.regionSelector.classList.remove('is-invalid');
            this.citySelector.classList.remove('is-invalid');
            this.updateCityList(e.currentTarget.value);
        });

        this.citySelector.addEventListener('change', (e) => {
            this.selectedCity = e.target.value;
        });
    }

    async updateCityList(id) {
        this.clearCitySelector();
        
        this.selectedRegion = id;
        
        if (id == "" || id == undefined) {
            this.citySelector.setAttribute('disabled', true);
            this.selectedCity = null;
            return;
        }

        const cities = await this.getCityListByRegion(id);

        cities.forEach((city) => {
            var option = document.createElement('option');
            option.value = city.id;
            option.innerHTML = city.title;
            this.citySelector.appendChild(option);
        });

        this.selectedCity = cities[0].id;
        this.citySelector.removeAttribute('disabled');
    }

    clearCitySelector() {
        this.citySelector.querySelectorAll('option').forEach(el => {
            el.remove();
        });
    }
    
    getCityListByRegion(regionId) {
        return $.getJSON(`/api/region/${regionId}/cities`);
    }

    invalidFields() {
        if (!this.selectedCity)
            this.citySelector.classList.add('is-invalid');
        
        if (!this.selectedRegion)
            this.regionSelector.classList.add('is-invalid');
        
        return (this.selectedRegion && this.selectedCity);
    }

    clearValidationErrors() {
        this.regionSelector.classList.add('is-invalid');
        this.citySelector.classList.add('is-invalid');
    }
}