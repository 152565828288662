require('./bootstrap');

import { Rating } from './rating';
import { Order } from './order';
import { Survey } from './survey';

var ratings = document.querySelectorAll('.rating-stars');

ratings.forEach(el => {
    // new Rating(el);
});

new Order();
new Survey();