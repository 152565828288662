export class Rating {   
    constructor(rating, onSelect = null) {
        this.rating = rating;
        this.onSelect = onSelect;
        this.ratingLevel = null;

        this.ratingName = rating.getAttribute('name')
        this.stars = rating.querySelectorAll('.star');
        this.stars.forEach(el => {
            el.addEventListener('mouseenter', (event) => this.starHover(event));
            el.addEventListener('click', (event) => this.starClick(event))
        });

        this.rating.addEventListener('mouseleave', (event) => this.resetStars(event))
    }

    starHover(event) {
        const target = event.currentTarget;
        const ratingLevel = parseInt(target.dataset.rating);

        for (let i = 0; i < this.stars.length; i++) {
            const star = this.stars[i];
            
            (i < ratingLevel) ? star.classList.add('hover') : star.classList.remove('hover');
        }
    }

    starClick(event) {
        const target = event.currentTarget;
        const ratingLevel = parseInt(target.dataset.rating);

        for (let i = 0; i < this.stars.length; i++) {
            const star = this.stars[i];
            
            (i < ratingLevel) ? star.classList.add('active') : star.classList.remove('active');
        }

        this.ratingLevel = ratingLevel;

        this.onSelect ? this.onSelect() : null;
    }

    resetStars(event) {
        this.stars.forEach(el => {
            el.classList.remove('hover')
        });
    }

    resetRating() {
        this.ratingLevel = null;

        this.stars.forEach(el => {
            el.classList.remove('active')
        });
    }

    getRating() {
        return this.ratingLevel;
    }
}