import { ProductSelector } from './product-selector';
import { RegionSelector } from './region-selector';
import { Rating } from './rating';

export class Order {
    constructor() {
        this.initOrderForm();
        this.initOrderResulSurveyForm();

        $('#orderSurvey').on('hidden.bs.modal', () => {
            this.resetOrderForm();
        });
    }

    initOrderForm() {
        this.orderForm = document.querySelector('.order-form');

        if (!this.orderForm)
            return;

        this.productSelector = new ProductSelector();
        this.RegionSelector = new RegionSelector();
        this.submitButton = this.orderForm.querySelector('.submit-btn');
        
        this.emailInput = this.orderForm.querySelector('[name=email]');
        this.phoneInput = this.orderForm.querySelector('[name=phone]');
        this.contactPersonInput = this.orderForm.querySelector('[name=contact_person]');
        this.companyInput = this.orderForm.querySelector('[name=company]');

        this.submitButton.addEventListener('click', (e) => this.onFormSubmitClick(e));
        this.emailInput.addEventListener('input', () => this.emailInput.classList.remove('is-invalid'));
        this.phoneInput.addEventListener('input', () => this.phoneInput.classList.remove('is-invalid'));
    }

    initOrderResulSurveyForm() {
        this.orderSurveyModal = document.querySelector('#orderSurvey');
        this.orderResultSendBtn = this.orderSurveyModal.querySelector('.send-btn');
        this.orderSurveyResultMessage = this.orderSurveyModal.querySelector('.survey-result-message');
        this.commentInput = this.orderSurveyModal.querySelector('[name=comment]');
        this.serviceRating = new Rating(
            this.orderSurveyModal.querySelector('.rating-stars[name=service_rating]'),
            this.activateResultSurveySubmitBtn.bind(this)
        );
        this.orderResultSendBtn.addEventListener('click', (e) => this.onSurveySubmitClick(e));
    }

    resetFormErrors() {
        this.orderForm.querySelectorAll('.is-invalid').forEach(el => el.classList.remove('is-invalid'));
    }

    onFormSubmitClick(e) {
        e.preventDefault();

        this.resetFormErrors();
        let isValid = true;
        
        const email = this.emailInput.value;
        const phone = this.phoneInput.value;

        if (email == "" || !this.testEmail(email)) {
            this.emailInput.classList.add('is-invalid');
            isValid = false;
        }
        
        if (phone == "") {
            this.phoneInput.classList.add('is-invalid');
            isValid = false;
        }

        if (!this.RegionSelector.selectedCity) {
            isValid = this.RegionSelector.invalidFields();
        }

        if (!isValid)
            return;

        const data = {
            products: this.productSelector.products,
            city: parseInt(this.RegionSelector.selectedCity),
            email: email,
            phone: phone,
            contact_person: this.contactPersonInput.value,
            company: this.companyInput.value,
        };

        this.submitForm(data);
    }

    submitForm(data) {
        $.post('/api/order', data)
            .then(res => {
                this.showResultModal(res.data.title, res.data.message, res.success)
            })
            .catch(res => {
                this.showResultModal(res.data.title, res.data.message, false)
            })
    }

    testEmail(email) {
        const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        return emailRegex.test(email);
    }

    showResultModal(title, text, success) {
        const orderResult = this.orderSurveyModal;
        orderResult.classList.remove('success', 'error');
        
        orderResult.querySelector('.result-title').innerText = title;
        orderResult.querySelector('.result-text').innerText = text;

        success ? orderResult.classList.add('success') : orderResult.classList.add('error');
        
        $('#orderSurvey').modal('show');
    }

    resetOrderForm() {
        this.emailInput.value = "";
        this.phoneInput.value = "";
        this.companyInput.value = "";
        this.contactPersonInput.value = "";
        this.productSelector.clearCart();
    }

    // order result modal
    activateResultSurveySubmitBtn() {
        if (this.serviceRating.getRating()) {
            this.orderResultSendBtn.removeAttribute('disabled');
        }
    }

    onSurveySubmitClick(e) {
        const comment = this.commentInput.value;
        
        const data = {
            service_rating: this.serviceRating.getRating(),
            source: 'order',
            email: this.emailInput.value,
            phone: this.phoneInput.value,
            comment: comment
        };

        $.post('/api/survey', data)
            .then(res => {
                this.orderSurveyResultMessage.innerText = res.data.message;

                if (res.success) {
                    this.orderSurveyResultMessage.classList.add('text-success');
                    return;
                }
                
                this.orderSurveyResultMessage.classList.add('text-danger');
            })
            .catch(res => {
                this.orderSurveyResultMessage.innerText = res.data.message;
                this.orderSurveyResultMessage.classList.add('text-danger');
            })
            .done(res => {
                this.orderSurveyModal.classList.add('result');
                this.resetOrderSurveryForm(); 
            });
    }

    resetOrderSurveryForm() {
        this.commentInput.value = "";
        this.serviceRating.resetRating();
    }
}