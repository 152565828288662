import { Rating } from './rating';
 
export class Survey {
    constructor() {
        this.surveyModal = document.querySelector('#survey');
        this.surveyForm = this.surveyModal.querySelector('.survey-form');
        this.surveyResultMessage = this.surveyModal.querySelector('.survey-result-message');

        this.sendBtn = this.surveyForm.querySelector('.send-btn');
        this.emailInput = this.surveyForm.querySelector('[name=survey-email]');
        this.phoneInput = this.surveyForm.querySelector('[name=survey-phone]');
        this.commentInput = this.surveyForm.querySelector('[name=comment]');

        this.productRating = new Rating(
            this.surveyForm.querySelector('.rating-stars[name=product-rating]'),
            this.activateSubmitBtn.bind(this)
        );
        this.serviceRating = new Rating(
            this.surveyForm.querySelector('.rating-stars[name=service-rating]'),
            this.activateSubmitBtn.bind(this)
        );

        this.sendBtn.addEventListener('click', (e) => {
            this.sendForm();
        });
    }

    activateSubmitBtn() {
        if (this.serviceRating.getRating() && this.productRating.getRating()) {
            this.sendBtn.removeAttribute('disabled');
        }
    }

    sendForm(e) {
        const email = this.emailInput.value;
        const phone = this.phoneInput.value;
        const comment = this.commentInput.value;
        this.emailInput.classList.remove('is-invalid');
        this.phoneInput.classList.remove('is-invalid');

        if (email && !this.testEmail(email)) {
            this.emailInput.classList.add('is-invalid');
            return;
        }

        if (!phone || phone.length < 7) {
            this.phoneInput.classList.add('is-invalid');
            return;
        }
        
        const data = {
            service_rating: this.serviceRating.getRating(),
            product_rating: this.productRating.getRating(),
            source: 'survey',
            email: email,
            phone: phone,
            comment: comment
        };

        $.post('/api/survey', data)
            .then(res => {
                this.surveyResultMessage.innerText = res.data.message;

                if (res.success) {
                    this.surveyResultMessage.classList.add('text-success');
                    return;
                }
                
                this.surveyResultMessage.classList.add('text-danger');
            })
            .catch(res => {
                this.surveyResultMessage.innerText = res.data.message;
                this.surveyResultMessage.classList.add('text-danger');
            })
            .done(() => {
                this.surveyModal.classList.add('result');
                this.resetForm(); 
            });
    }

    resetForm() {
        this.emailInput.value = "";
        this.commentInput.value = "";
        this.phoneInput.value = "";

        this.productRating.resetRating();
        this.serviceRating.resetRating();

        this.surveyResultMessage.classList.remove('text-danger text-success');
        this.sendBtn.setAttribute('disabled', true);
    }
    
    testEmail(email) {
        const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        return emailRegex.test(email);
    }
}