export class ProductSelector {
    constructor() {
        this.cartList = document.querySelector('.cart .cart-list');

        if (!this.cartList)
            return;
        
        this.cartProductTemplate = document.querySelector('.cart .cart-product-template');
        this.cartSumEl = document.querySelector('.cart .total .amount');
        this.submitButton = document.querySelector('.order-form .submit-btn');
        this.amountInputs = document.querySelectorAll('input[name=amount]');

        this.cartSum = 0;
        this.products = [];
        this.productSelectorsAddBtn = document.querySelectorAll('.product-selector .add-btn');

        this.initProductSelecor();
        this.initCartFunctions();
    }

    initProductSelecor() {
        this.productSelectorsAddBtn.forEach(el => {
            el.addEventListener('click', (e) => this.addProductBtnClick(e));
        });

        this.amountInputs.forEach(el => {
            el.addEventListener('input', this.filterValue);
        });
    }

    filterValue() {
        if (this.value < 0) {
            this.value = this.value * -1
        }
    }

    initCartFunctions() {
        this.cartList.addEventListener('click', (e) => {
            const target = e.target;
            if (target.classList.contains('remove')) {
                this.removeProduct(target.closest('.cart-product'));
            }
        });
    }

    addProductBtnClick(event) {
        const target = event.currentTarget;
        const parentContainer = target.closest('.product-selector');
        const select = parentContainer.querySelector('select');
        const amountInput = parentContainer.querySelector('input');
        const minAmount = amountInput.getAttribute('min') ?? 1;

        select.classList.remove('is-invalid');
        amountInput.classList.remove('is-invalid');

        if (select.value == "" || select.value == undefined) {
            select.classList.add('is-invalid');
            return;
        }

        if (amountInput.value == "" || amountInput.value < minAmount || amountInput.value == undefined) {
            amountInput.classList.add('is-invalid');
            return;
        }

        const productName = select.selectedOptions[0].text;
        const productId = select.value;
        const productAmount = parseFloat(amountInput.value).toFixed(2);

        this.addProduct(productId, productName, productAmount);
        amountInput.value = "";
    }

    addProduct(id, name, amount) {
        // const product = this.cartList.querySelector(`.cart-product[data-id='${id}']`);
        const productIndex = this.products.findIndex (el => el.id == id)
        amount = parseFloat(parseFloat(amount).toFixed(2));
        
        if (productIndex != -1) {
            const newAmount = this.products[productIndex].amount + amount;
            this.products[productIndex].amount = newAmount;
            this.editCartProductNode(id, newAmount);
        } else {
            const product = {
                'id': id,
                'name': name,
                'amount': amount
            };

            this.products.push(product);

            this.addCartProductNode(id, name, amount);
        }
            
        this.recalculateSum(amount);
    }

    editCartProductNode(id, amount) {
        const product = this.cartList.querySelector(`.cart-product[data-id='${id}']`);

        product.setAttribute('data-amount', amount);
        product.querySelector('.amount').innerText = amount;
    }

    addCartProductNode(id, name, amount) {
        const newCartProduct = this.cartProductTemplate.cloneNode(true);
        newCartProduct.setAttribute('data-id', id);
        newCartProduct.setAttribute('data-name', name);
        newCartProduct.setAttribute('data-amount', amount);
        
        newCartProduct.querySelector('.title').innerText = name;
        newCartProduct.querySelector('.amount').innerText = amount;

        newCartProduct.classList.remove('d-none', 'cart-product-template');
        newCartProduct.classList.add('cart-product');

        this.cartList.appendChild(newCartProduct);
    }

    removeProduct(product) {
        const id = product.dataset.id;
        const productIndex = this.products.findIndex(el => el.id == id);
        this.products.splice(this.productIndex, 1);
        const amount = parseFloat(product.dataset.amount);
        this.recalculateSum(amount * -1);
        product.remove();
    }

    recalculateSum(amount) {
        const sum = this.cartSum + amount;
        this.cartSum = parseFloat(sum.toFixed(2));
        this.cartSumEl.innerText = this.cartSum;

        this.changeButtonStatus();
    }

    changeButtonStatus() {
        if (this.products.length) {
            this.submitButton.removeAttribute('disabled');
        } else {
            this.submitButton.setAttribute('disabled', true);
        }
    }

    clearCart() {
        this.products = [];
        this.cartList.querySelectorAll('.cart-product').forEach(el => el.remove());
        this.cartSumEl.innerText = 0;
        this.cartSum = 0;
        this.changeButtonStatus();
    }

}